import React, { useState } from 'react';


function ScrollToTopButton() {
    const [isVisible, setIsVisible] = useState(false);

    // Function to scroll to the top of the page
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    // Event listener to check the scroll position
    window.addEventListener('scroll', () => {
        if (window.scrollY > 200) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    });

    return (
        <>
            {isVisible && (
                <button className="scroll-to-top-button" onClick={scrollToTop}>
                    <i className="fa-solid fa-arrow-up"></i>
                </button>
            )}
        </>
    );
}

export default ScrollToTopButton;
