import React from 'react';
import shaileshsir from './image/TeamImage/shaileshsir.png';
import akashsir from './image/TeamImage/akashsir.png';
import ankitsir from './image/TeamImage/ankitsir.png';
import ishitamam from './image/TeamImage/ishitamam.png';
import surajlahre from './image/TeamImage/surajlahre.png';
import SingleFacultyCart from './SingleFacultyCard';


function TeamArena() {
  return (
    <>
      <div>
        <h1 className='text-center p-3 headingh1text'>OUR TEAM</h1>
      </div>
      {/* myteam section  START*/}
      <div className='container-fluid team-card-container'>
        <SingleFacultyCart img={shaileshsir} experince="20 Years Experience" name="Shailesh Bagde" position="Director" />
        <SingleFacultyCart img={akashsir} experince="15 Years Experience" name="Akash Sinha" position="Academic Head" />
        <SingleFacultyCart img={ishitamam} experince="2 Years Experience" name="Ishita Singh" position="3D Faculty / Career Counselor" />
        <SingleFacultyCart img={surajlahre} experince="2.5 years Experience" name="Suraj Lahre" position="Web Developer" />
        <SingleFacultyCart img={ankitsir} experince="10 years Experience" name="Ankit Martin" position="3D/VFx & Gaming" />
      </div>
    </>
  )
}

export default TeamArena
