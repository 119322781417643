import React from 'react';


const SingleFacultyCart = (props) => {
    return (
        <div className='teamcard'>
            <div>
                <div children='card-icon p-3'> <img src={props.img} alt="Avatar" className="image" /></div>
                <div className='card-text p-2 '>
                    <p className='paracard'>{props.experince}</p>
                    <h5>{props.name}</h5></div>
                <div className='card-text p-2 paracard'>{props.position}</div>
            </div>
        </div>

    );
}

export default SingleFacultyCart;
