import React from 'react'
import { Link } from 'react-router-dom'
import anivfx from './image/CourseImage/anivfx.jpg'
function AniVfx() {
    return (
        <>
            <h3 className='text-center my-5' >Animation VFx (AniVFx Prime) Course At Arena Raipur</h3>
            <div className='container-fluid p-3'>
                <div className='animation-content d-flex'>
                    <div className='animation-page-text-heading p-3'>
                        <h5 className='text-danger' >COURSE HIGHLIGHTS</h5>
                        <ul><li>Job-oriented, industry centric curriculum</li>
                            <li>Hands-on practical training using latest tools and software</li>
                            <li>Certified faculty</li><li>Exposure to industry interaction and workshops</li>
                            <li>Placement assistance</li>
                            <li>Access to</li>
                            <li>Instant student loan facility to fund the studies</li>
                            <li>Learning enhanced with Augmented Reality books</li>
                            </ul>
                </div>
                <div>
                    <img src={anivfx} alt='animationcourse' width={'100%'}></img>
                </div>
            </div>

            <div className='animation-page-text-heading w-100 p-3'>
                <h4 className='text-danger' >ANI-VFx</h4>
                <p>It offers complete training in all aspects of animation to make its students capable of employment and progress in the animation industry.</p>
            </div>
        </div >

            {/* term card section */ }
            < div className = 'term-card-container container-fluid' >
                <h4 className='text-center'>Animation-VFx comprehensively trains students in a duration of three terms</h4>
                <div className='term-card-body'>
                    <div className='m-auto my-2 mx-2 termcard'>
                        <ul className='ul-list-first p-4'>
                            <h4>TERM 1</h4>
                            <li>Digital Design</li>
                            <li>Concepts of Design &amp; Composition</li>
                            <li>Lights, Colour &amp; Perspectives</li>
                            <li>Concepts of Cinematography &amp; PhotographyApplication of 2D Animation Principles</li>
                            <li>Anatomy -Clay Modeling &amp; Animation</li>
                            <li>Character Designing</li>
                            <li>Audio -Video Editing</li>
                            <li>Storytelling &amp; Script Writing</li>
                            <li>Storyboarding and Animatics</li>
                            <li>Pre-Production Design Portfolio</li>
                        </ul>
                    </div>
                    <div className='m-auto mx-2 my-2 termcard'>
                        <ul className='ul-list-first p-4'>
                            <h4>TERM 2</h4>
                            <li>3D Basics -Modeling to Animation</li>
                            <li>Digital Modeling with Maya</li>
                            <li>Digital Sculpting</li>
                            <li>Texturing 3D Models with Maya</li>
                            <li>Lighting and Rendering Models with Maya</li>
                            <li>Rigging 3D Models with Maya</li>
                            <li>3D Character Animation</li>
                            <li>FX Dynamics &amp; Simulation</li>
                            <li>Photogrammetry &amp; 3D Printing</li>
                            <li>Working with Arnold Renderer</li>
                            <li>Digital Compositing</li>
                            <li>3D Portfolio</li>

                        </ul>
                    </div>
                    <div className='m-auto mx-2 my-2 termcard'>
                        <ul className='ul-list-first p-4'>
                            <h4>TERM 3</h4>
                            <li>VFX Storytelling &amp; 3D Pre-visualization</li><li>Introduction to Nuke</li><li>Rotoscopy</li><li>Roto Paint &amp; Wire removal</li><li>Keying (Green/ Blue screen)</li>
                            <li>Matte Painting</li>
                            <li>Matchmoving</li>
                            <li>VFX Compositing with Nuke</li>
                            <li>VFX Portfolio</li>
                        </ul>
                    </div>
                </div>
            </div >
            <div className='container text-center p-5'>
      <Link to='/enquiry'><button className='btn btn-danger '>Enroll Now</button></Link>
        <strong className='d-block my-2 fs-5 p-3'>On completion, this program opens up a wide range of job roles for the students</strong>
        <strong className='d-block my-2 fs-6 p-3'>Graphic Designer | 3D Animator | Texturing Artist | Lighting Artist | Video Editor | Rigging Artist | Compositor Rendering | Artist 3D Modeler</strong>
      </div>
        </>
    )
}

export default AniVfx;
