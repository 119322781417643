import React from 'react';
import aboutArena from './image/aboutArena.png';
import { Link } from 'react-router-dom';

const About = () => {
    return (
        <>
            <div className="container col-xxl-8 px-4 py-5">
                <div className="row flex-lg-row-reverse align-items-center g-5 py-5 justify-content-center">
                    <div className="col-10 col-sm-8 col-lg-6">
                        <img src={aboutArena} className="d-block mx-lg-auto img-fluid" alt="Bootstrap Themes" width="400" height="400" loading="lazy" />
                    </div>
                    <div className="col-lg-6">
                        <h1 className="display-5 fw-bold text-body-emphasis lh-1 mb-3">About Arena Animation Raipur</h1>
                        <p className="lead">Arena Animation Raipur offers industry relevant courses and is committed to skilling the youth. Arena Raipur not only provides our students technical skills, but also empowers them with interpersonal skills, which are mandatory for a good Lifestyle. We Are Dedicated to Teach courses Like Animation, VFx, Game Development, Audio/Video editing, Website Development and Graphic Designing. </p>

                        <div className="d-grid gap-2 d-md-flex justify-content-md-start">
                            <Link to='/course'> <button type="button" className="btn btn-warning px-4 me-md-2">Explore Our Courses</button></Link>
                            <Link to='/enquiry'> <button type="button" className="btn btn-dark  px-4">Want to Join</button></Link>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <h1 className="display-5 text-center fw-bold text-body-emphasis lh-1 mb-5">ARENA ANIMATION – CELEBRATING 27 YEARS OF EXCELLENCE</h1>
                    <p className="lead text-center">This year marks the completion of 27 years of Arena Animation as a leader in vocational training. It is a significant milestone not just in view of the outstanding accomplishments along the way, but also considering the lives touched in the past two and a half decades. </p>
                    <p className="lead text-center">As the nation enters the 75th year of its independence, it is heartening to note that Arena Animation has spent a third of this time in shaping careers of the youth
                        with our career-oriented and skill-building courses.</p>
                    <p className="lead text-center">With strong industry connects and affiliations, Arena Animation has been designing academic courses that are relevant, imparting skillsets that make students highly employable. Arena students are placed as Graphic Designers, Web Designers, 2D/3D Animators, 2D/3D Designers, AV Editors, Technical Trainers, 3D Modelers, Multimedia Programmers, Compositors, Visualizers, Content Developers and Pre and Post Production Executives in blue chip animation studios and entertainment companies.</p>
                </div>







            </div>
        </>
    );
}

export default About;
