import React from 'react';
import Arena_Raipur_Virtual_Tour_1080p from './video/Arena_Raipur_Virtual_Tour_1080p.b14fdf1649a564a9a8cd.mp4';

function IndroVidio() {
    return (
        <>
            <div className="vidio-container container-fluid d-flex my-3 p-5 border">
                <div className="video-para text-light w-100  m-auto  p-3 text-center">
                    <h3>Take a Break &amp; Checkout Our Courses</h3>
                    <p className="text-wrap">Since 1996, Arena Academy has focused on providing quality education
                        in Animation, VFX, Web Designing, Graphic Designing, Broadcast,
                        &amp; Multimedia. Arena students have got job placements with major Indian &amp;
                        international studios.</p>
                    <button className="btn btn-warning btn-sm rounded p-2">
                        <a href="#enquiryid">EXPLORE MORE</a>
                    </button></div>
                <div className="video-para text-light w-100 p-1">
                    <video src={Arena_Raipur_Virtual_Tour_1080p} width="100%" muted loop controls autoPlay>
                    </video>
                </div>
            </div>
        </>
    )
}

export default IndroVidio
